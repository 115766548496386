<template>
  <div class="login">
    <div class="loginBg"></div>
    <el-form ref="form" :model="loginForm" class="loginContainer" :rules="rules">
      <div class="loginLogo">
        <img src="@/assets/images/logo.png" alt="" />
        <p>中心云控平台</p>
      </div>
      <el-form-item prop="username">
        <div class="loginInput">
          <img src="@/assets/images/iconname.png" alt="" />
          <el-input
            type="text"
            v-model="loginForm.username"
            placeholder="用户名"
            @blur="blurUserName()"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item prop="password">
        <div class="loginInput">
          <img src="@/assets/images/password.png" alt="" />

          <el-input
            type="password"
            v-model="loginForm.password"
            placeholder="密码"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item v-show="isNewAccount" prop="phone">
        <el-input v-if="isNewAccount" v-model="loginForm.phone" placeholder="输入注册手机号"></el-input>
      </el-form-item>
      <el-form-item prop="code">
        <el-col :span="16">
          <div class="loginInput">
            <img src="@/assets/images/code.png" alt="" />
            <el-input
              type="text"
              v-model="loginForm.code"
              placeholder="验证码"
              class="loginInput code"
              @keypress.native.enter="submitLogin"
            >
            </el-input>
          </div>
        </el-col>
        <el-col :span="6" :offset="1">
          <!-- <VueImgVerify ref="verify"></VueImgVerify> -->
          <img
            :src="'data:image/png;base64,' + codeImg"
            alt=""
            srcset=""
            class="codeImg"
            @click="getCode"
          />
        </el-col>
      </el-form-item>

      <div style="margin-bottom: 15px">
        <el-checkbox v-model="checked"></el-checkbox
        ><span class="automaticsty"> 自动登录</span>
      </div>
      <el-form-item>
        <el-button
          type="primary"
          class="loginBtn"
          @click="submitLogin"
          :loading="loginLoading"
          >登录</el-button
        >
        <!-- <img src="../../assets/images/loginActive.png" /> -->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { _Login, _GetJwtToken, _GetUserInfo, _GetCode, _GetAccountIfActive } from "@/api/login/index";
import VueImgVerify from "../../components/VueImgVerify.vue";
import { _FindAllPage } from "@/api/selCar";
import { compileStr, unCompileStr } from "@/until/helper";
export default {
  data() {
    return {
      labelPosition: "right",
      loginLoading: false,
      checked: true,
      codeImg: "",
      loginForm: {
        username: "",
        password: "",
        phone: '',
        code: "",
        type: 1,
        securityCode: "",
      },
      code: "7 3 6 4",
      rules: {
        username: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      isNewAccount: false,
    };
  },
  mounted() {
    // this.randomCode();
    this.getCode();
    this.getCookie();
  },
  components: {
    VueImgVerify,
  },
  methods: {
    //获取验证码
    async getCode() {
      let { data, success, message } = await _GetCode();
      if (success) {
        console.log(data);
        this.codeImg = data.imageBase64;
        this.loginForm.securityCode = data.securityCode;
      }
    },
    async blurUserName() {
      let resp = await _GetAccountIfActive({userName: this.loginForm.username})
      if(resp.data) {
        if(resp.data.activate) {
          this.isNewAccount = false;
        } else {
          this.isNewAccount = true;
        }
      } else {
        this.isNewAccount = false;
      }
    },
    submitLogin() {
      // 验证码
      // console.log('verify', this.$refs.verify.imgCode);
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          // console.log(this.loginForm.vercode.toLowerCase(), this.$refs.verify.imgCode
          //     .toLowerCase());
          // if (this.loginForm.vercode.toLowerCase() != this.$refs.verify.imgCode
          //     .toLowerCase()) {
          //     this.$message.error("验证码不一致")
          //     this.$refs.verify.handleDraw()
          // } else {
          this.loginLoading = true;
          var that = this;
          // 登录前校验是否激活
          await this.blurUserName()
          if(!this.isNewAccount) {
            this.loginForm.phone = '';
          }
          //判断复选框是否被勾选 勾选则调用配置cookie方法
          if (this.checked == true) {
            console.log("checked == true");
            //传入账号名，密码，和保存天数3个参数
            this.setCookie(that.loginForm.username, that.loginForm.password, 7);
          } else {
            console.log("清空Cookie");
            //清空Cookie
            this.clearCookie();
          }
          // 判断账号是否激活，未激活且未输入电话不可登录
          if(this.isNewAccount && this.loginForm.phone == '') {
            this.$message.error('请输入电话用于激活账号！')
            this.loginLoading = false;
            return false;
          }
          this.loginApi();
          // this.automaticLogon()
          // }
        } else {
          return false;
        }
      });
    },
    //设置cookie
    setCookie(c_name, c_pwd, exdays) {
      var exdate = new Date(); //获取时间
      exdate.setTime(exdate.getTime() + 24 * 60 * 60 * 1000 * exdays); //保存的天数
      //字符串拼接cookie
      window.document.cookie =
        "userName2" + "=" + c_name + ";path=/;expires=" + exdate.toGMTString();
      window.document.cookie =
        "userPwd2" + "=" + compileStr(c_pwd) + ";path=/;expires=" + exdate.toGMTString();
    },
    //读取cookie
    getCookie: function () {
      console.log(document.cookie.length);
      if (document.cookie.length > 0) {
        var arrs = document.cookie.split("; "); //这里显示的格式需要切割一下自己可输出看下
        for (var i = 0; i < arrs.length; i++) {
          var arrs2 = arrs[i].split("="); //再次切割
          //判断查找相对应的值
          if (arrs2[0] == "userName2") {
            this.loginForm.username = arrs2[1]; //保存到保存数据的地方
            this.blurUserName(this.loginForm.username);
          } else if (arrs2[0] == "userPwd2") {
            this.loginForm.password = unCompileStr(arrs2[1]);
          }
        }
      }
    },
    //清除cookie
    clearCookie: function () {
      this.setCookie("", "", -1); //修改2值都为空，天数为负1天就好了
    },
    // 判断勾选自动登录
    // automaticLogon(token) {
    //     if (this.checked) {
    //         window.localStorage.setItem('checked', 1)
    //
    //     } else {
    //         window.localStorage.setItem('checked', 0)
    //         this.loginApi()
    //     }
    // },
    loginApi() {
      localStorage.removeItem("tokenStr");
      _Login(this.loginForm)
        .then((res) => {
          console.log(res);
          this.loginLoading = false;
          if (res.success) {
            window.localStorage.setItem("token", res.token);
            this.getJwtToken(res.token);
          } else {
            this.loginLoading = false;
            // this.$message.error(res.message)
            // this.$refs.verify.handleDraw()
            this.getCode();
          }
        }).catch((e) => {
          this.getCode();
          // this.$refs.verify.handleDraw()
        }).finally(() => {
          this.loginLoading = false;
        })
    },
    // 获取gwttoken
    getJwtToken(token) {
      _GetJwtToken({
        token,
      }).then((res) => {
        if (res.success) {
          const tokenstr = res.jwt_token;
          window.localStorage.setItem("tokenStr", tokenstr);
          this.getUserInfo();
        }
      });
    },
    // 获取用户信息
    async getUserInfo() {
      let { message, data, success } = await _GetUserInfo();
      // let {data,success,message}= await _FindAllPage({
      //     type: 1
      // })

      if (success) {
        window.localStorage.setItem("userInfo", JSON.stringify(data.map));
        this.$router.replace({
          path: data.menuList[0].children[0].url,
        });
        this.loginLoading = false;
      }
    },
  },
};
</script>

<style>
@import "./login.css";
</style>
