<template>
    <div>
        <div class="headDetail">
            <div class="detailQuery">
                <div>
                    车辆编号: <span> {{vehicleNumber}}</span>
                </div>
                <div>
                    所属园区: <span> {{park}}</span>
                </div>
            </div>

            <el-date-picker v-model="date" type="datetimerange" range-separator="-" class="centerDatepicker ml20"
                start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
            <button class="centerCzbtn ml20">搜索</button>

        </div>
        <div class="mapView mt20">
            <div class="map">
                <amap ref="myMap">
                    <amap-marker ref="myMarker" />
                </amap>
                <div class="play">
                    <img src="@/assets/images/play.png" alt="">
                    <div>倍速</div>
                </div>
            </div>
            <div class="trajectory">
                <span>轨迹列表</span>
            </div>
            <div class="vehicleData">
                <div class="tab">
                    <div v-for="(item,index) in topTab" @click="tabChange(index)">
                        <span :class="item.selected?'selectedCol':''">{{item.label}}</span>
                        <div class="divBg" v-show="item.selected"></div>
                    </div>

                </div>

            </div>
            <div class="vehicleList">
                <div class="tab mb20">
                    <div v-for="(item,index) in topTabList" @click="tabChangeList(index)">
                        <span :class="item.selected?'selectedCol':''">{{item.label}}</span>
                        <div class="divBg" v-show="item.selected"></div>
                    </div>
                </div>
                <div class="tabList">
                    <Table v-show="topTabList[0].selected" :data1="tableData" :columns1="columns" :total="10"
                        @getAjax="getAjax" :loading="loading" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        Amap,
        Marker,

    } from '@amap/amap-vue';
    import Table from '@/components/Table'
    export default {
        components: {
            Amap,
            AmapMarker: Marker,
            Table
        },
        data() {
            return {
                vehicleNumber: '',
                park: "",
                loading: false,
                date: "",
                topTab: [{
                        label: '车辆数据',
                        selected: true
                    },
                    {
                        label: '车辆接收到的V2X指令',
                        selected: false
                    },
                ],
                topTabList: [{
                        label: '车辆事件列表',
                        selected: true
                    },
                    {
                        label: '车辆告警列表',
                        selected: false
                    },
                ],
                tableData: [{
                    alarmName: "配送车",
                    startTime: "2020.02.09 13:49:52",
                    endTime: "2020.02.09 13:49:52"
                }, {
                    alarmName: "配送车",
                    startTime: "2020.02.09 13:49:52",
                    endTime: "2020.02.09 13:49:52"
                }, {
                    alarmName: "配送车",
                    startTime: "2020.02.09 13:49:52",
                    endTime: "2020.02.09 13:49:52"
                }, {
                    alarmName: "配送车",
                    startTime: "2020.02.09 13:49:52",
                    endTime: "2020.02.09 13:49:52"
                }],
                columns: [{
                    label: "告警名称",
                    prop: "alarmName",
                }, {
                    label: "开始时间",
                    prop: "startTime",
                }, {
                    label: "结束时间",
                    prop: "endTime",
                }]
            };
        },
        mounted() {
            this.vehicleNumber = this.$route.query.vehicleNumber
            this.park = this.$route.query.park
        },
        methods: {
            tabChange(index) {
                this.topTab.forEach((e, i) => {
                    this.topTab[i].selected = false
                })
                this.topTab[index].selected = true
            },
            tabChangeList(index) {
                this.topTabList.forEach((e, i) => {
                    this.topTabList[i].selected = false
                })
                this.topTabList[index].selected = true
            }
        }
    }
</script>

<style>
    .headDetail {
        display: flex;
    }

    .detailQuery {
        height: 36px;
        background-color: #fff;
        display: flex;
        align-items: center;
        padding: 0px 20px;
        color: #666;
        font-size: 14px;
    }

    .detailQuery>div:last-child {
        margin-left: 40px;
    }

    .detailQuery span {
        color: #333;
        font-size: 14px;
        font-weight: 550;
        margin-left: 5px;
    }

    .mapView {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

    }

    .mapView>div {
        width: 49%;
        background-color: #fff;
        box-sizing: border-box;
        margin-bottom: 20px;
    }
    .tabList .table{
        box-shadow:none !important
    }
    .map,
    .trajectory {
        height: 320px;
        position: relative;
    }

    .play {
        position: absolute;
        bottom: 0px;
        height: 40px;
        background: #6d6d6d;
        opacity: 0.8;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0px 20px;
        width: 100%;
        box-sizing: border-box;
        color: #fff;
    }

    .play img {
        cursor: pointer;
    }

    .play div {
        font-size: 14px;
        cursor: pointer;
        font-weight: 700;
    }

    .amap-copyright {
        opacity: 0;
    }

    .trajectory {
        padding: 20px 30px;

    }

    .trajectory span {
        font-size: 16px;
        color: #666;
    }

    .tab {
        display: flex;
        color: #666666;
        font-size: 16px;

    }

    .selectedCol {
        color: #1683D9;
        font-weight: 550;
    }

    .vehicleData {
        height: 385px;
        padding: 20px 30px;
    }

    .tab>div {
        margin-right: 75px;
        cursor: pointer;
        position: relative;
    }

    .vehicleList {
        height: 385px;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
    }


    .tabList {
        height: 350px;
    }
</style>
