<template>
    <div>
        <div class="acquisitionHeadF">
            <div class="acquisitionHead">
                <div>车辆编号：<span>{{vehicleNumber}}</span></div>
                <div>车辆类型：<span>{{vehicleType}}</span></div>
                <div>车辆子类型：<span>{{vehicleSubType}}</span></div>
                <div>车辆所属组织：<span>{{vehicleOrganization}}</span> </div>
            </div>
        </div>
        <div class="mt20">
            <el-form :inline="true">
                <el-form-item>
                    <el-select v-model="searchObj.platform" placeholder="请选择所属平台" class="centerSelInput">
                        <el-option v-for="item in platformList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <button class="centerCzbtn ">搜索</button>
                </el-form-item>
                <el-form-item>
                    <button class="centerBtn resetbtn">重置</button>

                </el-form-item>
            </el-form>

        </div>

        <Table :data1="tableData" :columns1="columns" :total="0" @getAjax="getAjax" :loading="loading" />

    </div>
</template>

<script>
    import Table from '@/components/Table.vue'

    export default {
        components: {
            Table
        },
        data() {
            return {
                searchObj: {
                    date: ""
                },
                loading: false,
                emptyImg: require('../../assets/images/empty.png'),
                vehicleNumber: 'SV10008',
                vehicleType: "微公交",
                vehicleSubType: "SharingVan1.0",
                vehicleOrganization: "东风技术中心园区",
                platformList: [{
                        value: 0,
                        label: "中心云",
                    },
                    {
                        value: 1,
                        label: "SaaS云",
                    },
                    {
                        value: 2,
                        label: "私有云",
                    }
                ],

                tableData: [],
                columns: [{
                        label: "编号",
                        prop: "vehicleNnumber",
                    },
                    {
                        label: "日期",
                        prop: "vehicleType",
                    },
                    {
                        label: "开始上传时间",
                        prop: "vehicleSubtype",
                    },
                    {
                        label: "停止上传时间",
                        prop: "organization",
                    },
                    {
                        label: "操作",
                        // fixed: "right",
                        width: 250,
                        render: (h, params) => {
                            return h("div", [
                                h(
                                    "span", {
                                        class: "operationA",
                                        on: {
                                            click: () => {
                                                this.$router.push('/healthAnalysis')
                                            },
                                        },
                                    },
                                    "健康监测"
                                )
                            ]);
                        },
                    }
                ]
            };
        },
        methods: {
            getAjax() {

            }
        }
    }
</script>

<style>
    .acquisitionHeadF {
        display: flex;
    }

    .acquisitionHead {
        color: #666;
        font-size: 14px;
        background-color: #fff;
        display: flex;
        height: 36px;
        align-items: center;
        box-sizing: border-box;
        padding: 0px 12px;
    }

    .acquisitionHead>div {
        margin-right: 40px;
    }

    .acquisitionHead span {
        color: #333333;
        font-size: 14px;
        font-weight: 600;
    }
</style>